<template>
  <v-container fill-height>
    <router-view></router-view>
  </v-container>
</template>

<script>
export default {
  name: 'AuthView',
};
</script>
